import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Modal from "react-modal";
import Pagination from "../../../examples/Pagination";
import { loadStripe } from "@stripe/stripe-js";

// Replace with your actual Stripe public key
const stripePromise = loadStripe(
  "pk_live_51NIuvRELKdOM3HNefMdJ7x2SG1fQtz7NXJElWU9dHFaulGE9Smr4cDNH4r1JOF1hd0xllUOtZJRBmvEN9M0x5QjF00RKHdARuz"
);

const Numbers0300 = () => {
  const navigate = useNavigate();
  const [callType, setCallType] = useState("Receive");
  const [tailor, setTailor] = useState("");
  const [tariffs, setTariffs] = useState({});
  const [whisperPrice, setWhisperPrice] = useState("");
  const [vsbPrice, setVsbPrice] = useState("");
  const [partialNumbers, setPartialNumbers] = useState({});
  const [category, setCategory] = useState("ALL");
  const [tailorPrice, setTailorPrice] = useState(0);
  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentNumber, setCurrentNumber] = useState("");
  const [currentNumberPrice, setCurrentNumberPrice] = useState("");
  const [destination, setDestination] = useState("");
  const [showButton, setShowButton] = useState(true);
  const [ButtonDisabled, setButtonDisabled] = useState(false);
  const [tariffPrice, setTariffPrice] = useState(0);
  const [tariff, setTariff] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [numbersPerPage] = useState(84);
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = (open) => {
    setSidebarOpen(open);
  };

  useEffect(() => {
    const fetchAllPrices = async () => {
      try {
        const [tariffResponse, whisperResponse, vsbResponse] =
          await Promise.all([
            axios.get(`https://back.faithlandline.co.uk/api/numbers/tariffs/prices`),
            axios.get(`https://back.faithlandline.co.uk/api/numbers/whisper/prices`),
            axios.get(`https://back.faithlandline.co.uk/api/numbers/vsb/prices`),
          ]);
        const whisperPriceString = whisperResponse.data.price;
        const vsbPriceString = vsbResponse.data.price;
        const parsedWhisperPrice = parseFloat(
          whisperPriceString.replace("£", "")
        );
        const parsedVsbPrice = parseFloat(vsbPriceString.replace("£", ""));

        setTariffs(tariffResponse.data);
        setWhisperPrice(parsedWhisperPrice);
        console.log(whisperResponse.data.price);
        setVsbPrice(parsedVsbPrice);
      } catch (error) {
        console.error("Error fetching prices:", error);
      }
    };

    fetchAllPrices();
  }, []);

  const mapTariffToPlan = (tariffData) => {
    if (!tariffData || !tariffData.TN1 || !tariffData.TN1[0]) {
      return [];
    }

    return [
      {
        name: "Basic",
        value: "TN1",
        price: `${tariffData.TN1[0].Price}`,
        features: [
          `Divert To Landlines & Mobiles`,
          `${tariffData.TN1[0].inclusive} Inclusive Diversion Mins`,
          `${tariffData.TN1[0]["PPM Landline"]} ppm to landlines after inclusive`,
          "1 Month Rolling Contract",
          "Free Setup & Connection",
          "No Hidden Costs",
        ],
      },
      {
        name: "Growing Business",
        value: "TN2",
        price: `${tariffData.TN2?.[0]?.Price || "N/A"}`,
        features: [
          `Divert To Landlines & Mobiles`,
          `${tariffData.TN2?.[0]?.inclusive || "N/A"} Inclusive Diversion Mins`,
          `${
            tariffData.TN2?.[0]?.["PPM Landline"] || "N/A"
          } ppm to landlines after inclusive`,
          "1 Month Rolling Contract",
          "Free Setup & Connection",
          "No Hidden Costs",
        ],
      },
      {
        name: "Enterprise",
        value: "TN3",
        price: `${tariffData.TN3?.[0]?.Price || "N/A"}`,
        features: [
          `2000 Inbound Mins To Mobile App`,
          `${tariffData.TN3?.[0]?.inclusive || "N/A"} Diversion Mins`,
          `${
            tariffData.TN3?.[0]?.["PPM Landline"] || "N/A"
          } ppm to landlines after inclusive`,
          "1 Month Rolling Contract",
          "Free Setup & Connection",
          "No Hidden Costs",
          "PLUS These Premium Features",
        ],
        premiumFeatures: [
          "Dedicated VoIP App With:",
          "Outbound Calling",
          "Call Transfer",
          "On Hold Music",
          "Conference Calling",
          "On Demand Audio Saving",
          "Call Whisper",
          "Virtual Switchboard",
          "No Hidden Costs",
        ],
      },
    ];
  };

  const plans = mapTariffToPlan(tariffs);

  const fetchPartialNumbers = async () => {
    try {
      const response = await axios.get(
        `https://back.faithlandline.co.uk/api/numbers/partial/0333`
      );
      setPartialNumbers(response.data);
      setShowButton(false);
    } catch (error) {
      console.error("Error fetching partial numbers:", error);
    }
  };

  const handleAddNumber = (number, price) => {
    setCurrentNumber(number);
    setCurrentNumberPrice(price);
    setIsModalOpen(true);
    setTailor("");
  };

  const handleConfirmNumber = () => {
    setSelectedNumbers([
      ...selectedNumbers,
      {
        number: currentNumber,
        price: currentNumberPrice,
        destination,
        tariff: tariff,
        tariffPrice: tariffPrice,
        tailorPrice: tailorPrice,
      },
    ]);
    setIsModalOpen(false);
    setDestination("");
    setCurrentNumber(null);
    setPartialNumbers({});
    setShowButton(true);
  };

  const calculateTotalPrice = () => {
    return selectedNumbers.reduce((total, number) => {
      const numberPrice = parseFloat(number.price) || 0;
      const tariffPrice = parseFloat(number.tariff) || 0;
      const tailorPrice = parseFloat(number.tailorPrice) || 0;
      return total + numberPrice + tariffPrice + tailorPrice;
    }, 0);
  };

  const handleProceedToPayment = async () => {
    const subtotal = calculateTotalPrice();
    const profit = parseFloat((subtotal * 0.25).toFixed(2)); // 25% profit
    const subtotalWithProfit = subtotal + profit;
    const vat = parseFloat((subtotalWithProfit * 0.2).toFixed(2));
    const total = subtotalWithProfit + vat + parseFloat(tailorPrice);
  
    try {
      // Save payment details in localStorage
      localStorage.setItem(
        "paymentDetails",
        JSON.stringify({
          amount: total.toFixed(2),
          currency: "eur", // Changed to EUR
          numbers: selectedNumbers,
          subtotal: subtotalWithProfit.toFixed(2),
          vat,
          tailorPrice: tailorPrice.toFixed(2),
        })
      );
  
      // Proceed with payment processing
      const response = await fetch("https://back.faithlandline.co.uk/api/payment", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          amount: total.toFixed(2),
          currency: "eur", // Changed to EUR
          numbers: selectedNumbers,
          subtotal: subtotalWithProfit.toFixed(2), // subtotal with profit
          vat,
          tailorPrice: tailorPrice.toFixed(2),
        }),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        // Assuming you're getting a sessionId to redirect the user
        const { sessionId } = data;
        const stripe = await stripePromise;
        await stripe.redirectToCheckout({ sessionId });
      } else {
        console.error("Error processing payment:", data.error);
        // Handle the error (e.g., show a notification to the user)
      }
    } catch (error) {
      console.error("Error occurred during payment processing:", error.message);
      // Handle the error (e.g., show a notification to the user)
    }
  };

  const filteredNumbers = Object.entries(partialNumbers).filter(
    ([number, price]) => {
      if (category === "ALL") return true;
      if (category === "Bronze" && price === "0") return true;
      if (category === "Silver" && price === "50") return true;
      if (category === "Gold" && price === "200") return true;
      if (category === "Platinum" && price === "1300") return true;
      return false;
    }
  );

  const TariffHandler = (e, tariffdata, price) => {
    e.preventDefault();
    if (tariff === tariffdata) {
      setTariff("");
      setTariffPrice(0);
    } else {
      setTariff(tariffdata);
      setTariffPrice(price);
    }
  };
  const TailorHandler = (e, tailordata, price, index) => {
    e.preventDefault();
    if (tailor === tailordata) {
      setTailor("");
      setTailorPrice(0); // Set to 0 or null to clear the price
    } else {
      setTailor(tailordata);
      setTailorPrice(price);
    }
  };

  const indexOfLastNumber = currentPage * numbersPerPage;
  const indexOfFirstNumber = indexOfLastNumber - numbersPerPage;
  const currentNumbers = filteredNumbers.slice(
    indexOfFirstNumber,
    indexOfLastNumber
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const [expandedPlanIndex, setExpandedPlanIndex] = useState(null);

  const handleToggleExpand = (index) => {
    setExpandedPlanIndex(expandedPlanIndex === index ? null : index);
  };

  const handleBuildNumberClick = (e, plan, index) => {
    fetchPartialNumbers();
    TariffHandler(e, plan.price, plan.value, index);

    if (index === 2) {
      setButtonDisabled(!ButtonDisabled);
      setTailor("Switchboard");
    } else {
      setButtonDisabled(false);
    }
  };

  function getCategoryBadge(price) {
    if (price >= 1300) {
      return "platinum";
    } else if (price >= 200) {
      return "gold";
    } else if (price >= 50) {
      return "silver";
    } else {
      return "bronze";
    }
  }
  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
       <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
   

      <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
      <Navbar toggleSidebar={toggleSidebar} />
        <div className="main-page">
          <section className="main-page__hero py-5">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="main-page__hero-content">
                    <h1 className="display-4 font-weight-bold text-primary mb-4">
                      0300 Numbers ' s'
                    </h1>
                    <p className="text-secondary h4">
                      National Telephone Numbers
                    </p>
                    <p className="border-start border-3 ps-3 my-4 text-muted">
                      0300 numbers are UK wide national numbers specifically for
                      use by public sector bodies and not-for-profit bodies such
                      as registered charities. All other types of business, such
                      as sole traders, partnerships and limited companies should
                      view our 0333 numbers.
                    </p>
                    <p className="text-muted">
                      0300 numbers were introduced by Ofcom as an alternative
                      number for organisations, who wish to offer their
                      consumers a single, trusted point of contact.
                    </p>
                    <p className="text-muted">
                      The cost of calling 0300 numbers is the same as calling a
                      standard landline number, be it from a landline or mobile.
                      Callers that have free inclusive call minutes to landlines
                      would also usually have calls to 0300 numbers included in
                      their bundles, potentially meaning that callers can call
                      you for free, even from their mobile.
                    </p>
                    <p className="text-muted">
                      Using an 0300 number for your inbound calls gives you the
                      flexibility to control instantly online how and when you
                      receive your calls whilst giving your callers a cost
                      effective way of calling you.
                    </p>
                    <a href="#chooseNumber">
                      <button className="btn btn-primary btn-lg mt-4">
                        Choose Your Number
                      </button>
                    </a>
                  </div>
                </div>
                <div className="col-lg-6 text-center">
                  <img
                    src="https://www.tamartelecommunications.co.uk/wp-content/themes/tamar/img/hero-lady.webp"
                    alt="Hero"
                    className="img-fluid rounded shadow-lg"
                  />
                </div>
              </div>
            </div>
          </section>

          <div className="borderline w-full h-[1px]"></div>

          <section id="chooseNumber" className="main-page__numbers py-5">
            <div className="container">
              <h2 className="text-center text-dark bg-gradient-to-t from-pink-600 to-red-500 mb-5">
                Step 1: What Type Of Service Do You Need?
              </h2>
              <div className="row justify-content-around">
                <div className="col-md-6 card shadow-lg p-4 mb-4">
                  <h3 className="text-center text-primary mb-3">
                    Receive Calls
                  </h3>
                  <p className="text-lg text-center">
                    Receive Calls with Our Classic Virtual Numbers
                  </p>
                  <p className="text-center font-weight-bold text-muted my-4">
                    Professional Business Phone Numbers. Simple, Affordable,
                    Effective
                  </p>
                  <ul className="list-unstyled px-4">
                    <li className="mb-3 d-flex align-items-start">
                      <span className="dot me-2"></span>
                      <div className="itemContent">
                        <p className="itemDescription">
                          Mobile landline numbers
                        </p>
                      </div>
                    </li>
                    <li className="mb-3 d-flex align-items-start">
                      <span className="dot me-2"></span>
                      <div className="itemContent">
                        <p className="itemDescription">
                          Free to call from landlines & mobiles
                        </p>
                      </div>
                    </li>
                    <li className="d-flex align-items-start">
                      <span className="dot me-2"></span>
                      <div className="itemContent">
                        <p className="itemDescription">
                          Numbers for a nationwide presence
                        </p>
                      </div>
                    </li>
                  </ul>
                  <a href="#choosePrice">
                    <button
                      onClick={() => setCallType("Receive")}
                      className={`btn btn-lg w-100 mt-4 ${
                        callType === "Receive"
                          ? "btn-warning"
                          : "btn-outline-primary"
                      }`}
                    >
                      {callType === "Receive"
                        ? "Selected"
                        : "Choose Your Number"}
                    </button>
                  </a>
                </div>

                <div className="col-md-6 card shadow-lg p-4 mb-4">
                  <h3 className="text-center text-primary mb-3">
                    Make & Receive Calls
                  </h3>
                  <p className="text-lg text-center">
                    Our Complete VoIP System
                  </p>
                  <p className="text-center font-weight-bold text-muted my-4">
                    Professional Business Phone Numbers. Simple, Affordable,
                    Effective
                  </p>
                  <ul className="list-unstyled px-4">
                    <li className="mb-3 d-flex align-items-start">
                      <span className="dot me-2"></span>
                      <div className="itemContent">
                        <p className="itemDescription">
                          Mobile landline numbers
                        </p>
                      </div>
                    </li>
                    <li className="mb-3 d-flex align-items-start">
                      <span className="dot me-2"></span>
                      <div className="itemContent">
                        <p className="itemDescription">
                          Free to call from landlines & mobiles
                        </p>
                      </div>
                    </li>
                    <li className="d-flex align-items-start">
                      <span className="dot me-2"></span>
                      <div className="itemContent">
                        <p className="itemDescription">
                          Numbers for a nationwide presence
                        </p>
                      </div>
                    </li>
                  </ul>
                  <a href="#choosePrice">
                    <button
                      onClick={() => setCallType("Make")}
                      className={`btn btn-lg w-100 mt-4 ${
                        callType === "Make"
                          ? "btn-warning"
                          : "btn-outline-primary"
                      }`}
                    >
                      {callType === "Make" ? "Selected" : "Choose Your Number"}
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </section>

          <section id="choosePrice" className="py-5 bg-light">
            <div className="container">
              <h2 className="text-center text-primary mb-4">
                Step 2: Select Your Tariff
              </h2>
              <p className="text-center text-muted mb-5">
                Choose a tariff with the amount of minutes you need each month
                per user
              </p>

              <div className="row">
                {plans && plans.length > 0 ? (
                  plans.map((plan, index) => (
                    <div key={index} className="col-md-4 mb-4">
                      <div
                        className={`card h-100 ${
                          index === 2
                            ? "border border-warning shadow"
                            : "shadow-sm"
                        }`}
                      >
                        {index === 2 && (
                          <div className="position-absolute top-0 end-0 badge bg-warning text-dark mt-2 me-2">
                            Most Popular
                          </div>
                        )}
                        <div className="card-body d-flex flex-column">
                          <h5 className="card-title text-center">
                            {plan.name}
                          </h5>
                          <h2 className="card-price text-center text-primary my-3">
                            ${plan.price}
                          </h2>
                          <p className="text-center text-muted mb-4">
                            <small>/Month + VAT</small>
                          </p>

                          <ul
                            className={`list-group list-group-flush mb-3 ${
                              expandedPlanIndex === index
                                ? ""
                                : "overflow-hidden"
                            }`}
                            style={{
                              maxHeight:
                                expandedPlanIndex === index ? "100%" : "200px",
                              transition: "max-height 0.3s ease",
                            }}
                          >
                            {plan.features.map((feature, idx) => (
                              <li
                                key={idx}
                                className="list-group-item d-flex align-items-start"
                              >
                                <span className="me-2 text-success">
                                  <i className="fas fa-check-circle"></i>
                                </span>
                                <span>{feature}</span>
                              </li>
                            ))}
                            {plan.premiumFeatures &&
                              plan.premiumFeatures.length > 0 && (
                                <>
                                  <li className="list-group-item text-center fw-bold text-primary">
                                    PLUS These Premium Features
                                  </li>
                                  {plan.premiumFeatures.map((feature, idx) => (
                                    <li
                                      key={idx}
                                      className="list-group-item d-flex align-items-start"
                                    >
                                      <span className="me-2 text-warning">
                                        <i className="fas fa-star"></i>
                                      </span>
                                      <span>{feature}</span>
                                    </li>
                                  ))}
                                </>
                              )}
                          </ul>

                          {(plan.features.length > 4 ||
                            (plan.premiumFeatures &&
                              plan.premiumFeatures.length > 0)) && (
                            <button
                              className="btn btn-link p-0 text-primary"
                              onClick={() => handleToggleExpand(index)}
                            >
                              {expandedPlanIndex === index
                                ? "Show Less"
                                : "Show More"}
                            </button>
                          )}

                          <a href="#tailor" className="mt-auto">
                            <button
                              className={`btn btn-lg w-100 mt-3 ${
                                tariffPrice === plan.value
                                  ? "btn-warning"
                                  : "btn-primary"
                              }`}
                              onClick={(e) =>
                                handleBuildNumberClick(e, plan, index)
                              }
                            >
                              {tariffPrice === plan.value
                                ? "Selected"
                                : "Build My Number"}
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="col-12">
                    <p className="text-center">Loading tariffs...</p>
                  </div>
                )}
              </div>
            </div>
          </section>

          <section id="tailor" className="main-page__numbers py-5">
            <h2 className="text-transparent bg-clip-text bg-gradient-to-t from-[#c6117d] to-[#e50471] text-center mb-4">
              Step 3: Tailor Your Number
            </h2>
            <div className="row justify-content-center">
              <div className="col-md-4 col-sm-12 mb-4">
                <div className="card shadow-sm h-100">
                  <div className="card-body d-flex flex-column align-items-center">
                    <h3 className="card-title text-center mb-3">
                      Call Whisper
                    </h3>
                    <p className="card-text text-muted text-center mb-4">
                      "Incoming Business Call"
                    </p>
                    <p className="text-center font-bold text-gray-500 py-3">
                      What's Included
                    </p>
                    <ul className="list-group list-group-flush w-100 mb-4">
                      <li className="list-group-item d-flex align-items-center">
                        <span className="badge bg-secondary me-2"></span>
                        <span>Call Whisper</span>
                      </li>
                      <li className="list-group-item d-flex align-items-center">
                        <span className="badge bg-secondary me-2"></span>
                        <span>Caller Announcement</span>
                      </li>
                      <li className="list-group-item d-flex align-items-center">
                        <span className="badge bg-secondary me-2"></span>
                        <span>Press 1 To Accept A Call</span>
                      </li>
                    </ul>
                    <p className="text-center font-bold text-gray-500 py-3">
                      {whisperPrice} Per Month +VAT
                    </p>
                    <button
                      onClick={(e) => {
                        TailorHandler(e, "Whisper", whisperPrice, 1);
                      }}
                      className={`btn btn-lg btn-block mt-auto ${
                        tailor === "Whisper" || tailor === "Switchboard"
                          ? "btn-warning"
                          : "btn-primary"
                      }`}
                      disabled={ButtonDisabled}
                    >
                      {tailor === "Whisper" ? (
                        <p>Selected</p>
                      ) : tailor === "Switchboard" ? (
                        <p>Included with Virtual Switchboard</p>
                      ) : tariff === "TF3" ? (
                        <p>Included With Unlimited</p>
                      ) : (
                        <p>Add to your number</p>
                      )}
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-sm-12 mb-4">
                <div className="card shadow-sm h-100">
                  <div className="card-body d-flex flex-column align-items-center">
                    <h3 className="card-title text-center mb-3">
                      Virtual Switchboard
                    </h3>
                    <p className="card-text text-muted text-center mb-4">
                      "Press 1 For Sales, 2 For Accounts..."
                    </p>
                    <p className="text-center font-bold text-gray-500 py-3">
                      What's Included
                    </p>
                    <ul className="list-group list-group-flush w-100 mb-4">
                      <li className="list-group-item d-flex align-items-center">
                        <span className="badge bg-secondary me-2"></span>
                        <span>Virtual Switchboard</span>
                      </li>
                      <li className="list-group-item d-flex align-items-center">
                        <span className="badge bg-secondary me-2"></span>
                        <span>Up To 10 Menu Options</span>
                      </li>
                      <li className="list-group-item d-flex align-items-center">
                        <span className="badge bg-secondary me-2"></span>
                        <span>Free Professional Greeting</span>
                      </li>
                    </ul>
                    <p className="text-center font-bold text-gray-500 py-3">
                      {vsbPrice} Per Month +VAT
                    </p>
                    <button
                      onClick={(e) => {
                        TailorHandler(e, "Switchboard", vsbPrice, 1);
                        console.log(tailorPrice);
                      }}
                      className={`btn btn-lg btn-block mt-auto ${
                        tailor === "Switchboard" ? "btn-warning" : "btn-primary"
                      }`}
                      disabled={ButtonDisabled}
                    >
                      {tailor === "Switchboard"
                        ? "Selected"
                        : "Add to your Number"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="container-fluid py-4">
          {/* Filter Section */}
          <div className="row mb-4">
            <div className="col-12">
              <div className="d-flex justify-content-between align-items-center">
                <h5>Select Category:</h5>
                <div class="btn-group" style={{flexWrap: "wrap"}}>
                  <button
                    className={`btn ${
                      category === "ALL" ? "btn-primary" : "btn-outline-primary"
                    }`}
                    onClick={() => setCategory("ALL")}
                  >
                    All
                  </button>
                  <button
                    className={`btn ${
                      category === "Bronze"
                        ? "btn-primary"
                        : "btn-outline-primary"
                    }`}
                    onClick={() => setCategory("Bronze")}
                  >
                    Bronze
                  </button>
                  <button
                    className={`btn ${
                      category === "Silver"
                        ? "btn-primary"
                        : "btn-outline-primary"
                    }`}
                    onClick={() => setCategory("Silver")}
                  >
                    Silver
                  </button>
                  <button
                    className={`btn ${
                      category === "Gold"
                        ? "btn-primary"
                        : "btn-outline-primary"
                    }`}
                    onClick={() => setCategory("Gold")}
                  >
                    Gold
                  </button>
                  <button
                    className={`btn ${
                      category === "Platinum"
                        ? "btn-primary"
                        : "btn-outline-primary"
                    }`}
                    onClick={() => setCategory("Platinum")}
                  >
                    Platinum
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Cards Section */}
          <div className="row">
            {currentNumbers.map(([number, price]) => (
              <div className="col-lg-4 col-md-6 mb-4" key={number}>
                <div
  className="card h-100 border-0 shadow-lg rounded-lg position-relative card-hover"
  style={{ overflow: 'hidden', transition: 'all 0.3s ease-in-out' }}
>
  {/* Card Content */}
  <div className="card-body d-flex justify-content-between align-items-center p-4">
    {/* Number Display */}
    <h5 className="card-title font-weight-bold mb-0">{number}</h5>

    {/* Category Badge */}
    <span
  className={`badge fs-6 px-3 py-2 ${
    getCategoryBadge(price) === "platinum"
      ? "bg-gradient-info"
      : getCategoryBadge(price) === "gold"
      ? "bg-gradient-warning"
      : getCategoryBadge(price) === "silver"
      ? "bg-gradient-success"
      : "bg-gradient-secondary"
  } custom-badge`}
>
  {getCategoryBadge(price)}
</span>

<style jsx>{`
  .custom-badge {
    font-size: 10px;  
  }
`}</style>

  </div>

  {/* Hover Elements (Price & Add Button) */}
  <div
    className="card-hover-content position-absolute d-flex flex-column align-items-center justify-content-center w-100 h-100"
    style={{
      top: '0',
      left: '0',
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      opacity: '0',
      transition: 'opacity 0.3s ease-in-out',
      color: 'white',
    }}
  >
    {/* Price Display */}
    <div className="mb-3">
      <span className="badge bg-primary fs-5 px-3 py-2">£{price}</span>
    </div>

    {/* Add Button */}
    <button
      className="btn btn-light btn-sm w-75 py-2"
      onClick={() =>
        handleAddNumber(number, price, getCategoryBadge(price))
      }
      style={{color:"black"}}
    >
      Add
    </button>
  </div>

  {/* Hover Effect */}
  <style jsx>{`
    .card:hover .card-hover-content {
      opacity: 1;
    }
  `}</style>
</div>

              </div>
            ))}
          </div>

          {/* Pagination */}
          <Pagination
            itemsPerPage={numbersPerPage}
            totalItems={filteredNumbers.length}
            paginate={paginate}
            currentPage={currentPage}
          />

          {/* Selected Numbers Section */}
          {selectedNumbers.length > 0 && (
            <div className="my-5">
              <h3>Selected Numbers:</h3>
              <ul>
                {selectedNumbers.map(({ number, destination, price }) => (
                  <li key={number}>
                    {number} - Destination: {destination} - £{price}
                  </li>
                ))}
              </ul>
              <button
                className="mt-3 btn btn-info text-white py-2 px-4 rounded"
                onClick={handleProceedToPayment}
              >
                Proceed to Payment
              </button>
            </div>
          )}

          {/* Modal */}
          <Modal
            isOpen={isModalOpen}
            onRequestClose={() => setIsModalOpen(false)}
            ariaHideApp={false}
            contentLabel="Enter Destination"
            className="custom-modal-content"
            overlayClassName="custom-modal-overlay"
          >
            <h2 className="modal-title">
              Enter Destination for {currentNumber}
            </h2>
            <input
              type="text"
              value={destination}
              onChange={(e) => setDestination(e.target.value)}
              placeholder="Enter destination"
              className="form-control"
            />
            <div className="modal-buttons">
              <button className="btn btn-primary" onClick={handleConfirmNumber}>
                Confirm
              </button>
              <button
                className="btn btn-secondary"
                onClick={() => setIsModalOpen(false)}
              >
                Cancel
              </button>
            </div>
          </Modal>
        </div>
        </div>
      </main>
    </div>
  );
};

export default Numbers0300;
