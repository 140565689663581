import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";

const HuntGroup = () => {
  const [huntGroups, setHuntGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null); // Changed to object
  const [isEditing, setIsEditing] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [newHuntGroupName, setNewHuntGroupName] = useState("");
  const [voicemailOptions, setVoicemailOptions] = useState([]);
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = (open) => {
    setSidebarOpen(open);
  };

  // Fetch hunt group data
  useEffect(() => {
    refreshHuntGroups();
    fetchVoicemailOptions();
  }, []);

  const refreshHuntGroups = async () => {
    const userId = localStorage.getItem("user_id");
    try {
      const response = await axios.get(
        `https://back.faithlandline.co.uk/api/hunt/list?user_id=${userId}`
      );
      setHuntGroups(response.data); // Set fetched hunt groups
    } catch (error) {
      console.error("Error fetching hunt groups:", error);
    }
  };

  const fetchVoicemailOptions = async () => {
    try {
      const response = await axios.get("/dummy-voicemail-api"); // Replace with actual API
      setVoicemailOptions(response.data.voicemails || []);
    } catch (error) {
      console.error("Error fetching voicemail options:", error);
    }
  };

  const handleGroupSelect = (e) => {
    const groupId = e.target.value;
    const group = huntGroups.find((g) => g.huntid === groupId);
    setSelectedGroup(group);
    setIsEditing(true);
    setIsCreating(false);
  };

  const handleCreateNew = () => {
    setIsCreating(true);
    setIsEditing(false);
    setSelectedGroup(null);
  };

  const handleCreate = () => {
    const userId = localStorage.getItem("user_id");
    const payload = {
      description: newHuntGroupName,
      user_id: userId,
    };

    axios
      .post("https://back.faithlandline.co.uk/api/hunt/create", payload)
      .then(() => {
        alert("Hunt group created successfully!");
        setIsCreating(false);
        setNewHuntGroupName("");
        refreshHuntGroups(); // Refresh Hunt Groups after creation
      })
      .catch((error) => {
        console.error("Error creating hunt group:", error);
      });
  };

  const handleUpdate = () => {
    const userId = localStorage.getItem("user_id");
    const payload = {
      huntid: selectedGroup.huntid,
      description: selectedGroup.description,
      huntingtype: selectedGroup.huntingtype,
      announcement: selectedGroup.announcement,
      voicemail: selectedGroup.voicemail,
      huntlineid: selectedGroup.huntlineid || null,
      days: selectedGroup.days || [],
      start: selectedGroup.start || "",
      end: selectedGroup.end || "",
      destination: selectedGroup.destination || "",
      timeout: selectedGroup.timeout || 20,
      enabled: selectedGroup.enabled || "on",
      user_id: userId,
    };

    axios
      .post("https://back.faithlandline.co.uk/api/hunt/update", payload)
      .then(() => {
        alert("Hunt group updated successfully!");
        refreshHuntGroups(); // Refresh Hunt Groups after update
      })
      .catch((error) => {
        console.error("Error updating hunt group:", error);
      });
  };

  return (
    <div>
      <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        <Navbar toggleSidebar={toggleSidebar} />
        <div className="container-fluid py-4">
          <div className="row">
            <div className="col-lg-12 col-md-10 mx-auto">
              {/* Hunt Group Administration Card */}
              <div className="card p-4 mb-4">
                <h3>Select an existing Hunt Group or create new</h3>

                <label className="form-label">Select Hunt Group:</label>
                <select
                  className="form-control border border-primary"
                  onChange={handleGroupSelect}
                  value={selectedGroup?.huntid || ""}
                >
                  <option value="" disabled>
                    Select Hunt Group
                  </option>
                  {huntGroups.map((group) => (
                    <option key={group.huntid} value={group.huntid}>
                      {group.description}
                    </option>
                  ))}
                </select>

                <div className="mt-3">
                  <button
                    className="btn btn-primary me-2"
                    onClick={() => setIsEditing(true)}
                    disabled={!selectedGroup}
                  >
                    View/Edit
                  </button>
                  <button className="btn btn-success" onClick={handleCreateNew}>
                    Create New
                  </button>
                </div>
              </div>

              {/* Create New Hunt Group Form */}
              {isCreating && (
                <div className="card p-4 mb-4">
                  <h3>Create A New Hunt Group</h3>
                  <label className="form-label">Description</label>
                  <input
                    className="form-control border border-primary"
                    type="text"
                    placeholder="Name for this hunt group"
                    value={newHuntGroupName}
                    onChange={(e) => setNewHuntGroupName(e.target.value)}
                  />
                  <button
                    className="btn btn-primary mt-3"
                    onClick={handleCreate}
                  >
                    Create
                  </button>
                </div>
              )}

              {/* Edit/View Hunt Group Card */}
              {isEditing && selectedGroup && (
                <div className="card p-4">
                  <h3>Hunt Group: {selectedGroup.description || "N/A"}</h3>

                  {/* Description */}
                  <label className="form-label">Description</label>
                  <input
                    className="form-control mb-3 border border-primary"
                    type="text"
                    value={selectedGroup.description || ""}
                    maxLength={64}
                    onChange={(e) =>
                      setSelectedGroup({
                        ...selectedGroup,
                        description: e.target.value,
                      })
                    }
                  />

                  {/* Announcement */}
                  <label className="form-label">Announcement</label>
                  <select
                    className="form-control mb-3 border border-primary"
                    value={selectedGroup.announcement || ""}
                    onChange={(e) =>
                      setSelectedGroup({
                        ...selectedGroup,
                        announcement: e.target.value,
                      })
                    }
                  >
                    <option value="">None</option>
                    <option value="default">Default Call Whisper</option>
                  </select>

                  {/* Voicemail */}
                  <label className="form-label">Voicemail</label>
                  <select
                    className="form-control mb-3 border border-primary"
                    value={selectedGroup.voicemail || ""}
                    onChange={(e) =>
                      setSelectedGroup({
                        ...selectedGroup,
                        voicemail: e.target.value,
                      })
                    }
                  >
                    {voicemailOptions.map((vm) => (
                      <option key={vm.id} value={vm.id}>
                        {vm.name}
                      </option>
                    ))}
                  </select>

                  {/* Hunting Type */}
                  <label className="form-label">Hunting Type</label>
                  <select
                    className="form-control mb-3 border border-primary"
                    value={selectedGroup.huntingtype || "inorder"}
                    onChange={(e) =>
                      setSelectedGroup({
                        ...selectedGroup,
                        huntingtype: e.target.value,
                      })
                    }
                  >
                    <option value="inorder">Hunt-in-order</option>
                    <option value="random">Random Hunt</option>
                    <option value="last">Last Found</option>
                    <option value="mostidle">Most Idle</option>
                    <option value="rotary">Rotary</option>
                    <option value="simultaneous">Simultaneous Ring All</option>
                  </select>

                  {/* Huntline ID */}
                  <label className="form-label">Huntline ID</label>
                  <input
                    className="form-control mb-3 border border-primary"
                    type="text"
                    value={selectedGroup.huntlineid || ""}
                    onChange={(e) =>
                      setSelectedGroup({
                        ...selectedGroup,
                        huntlineid: e.target.value,
                      })
                    }
                  />

                  {/* Days */}
                  <label className="form-label">Days</label>
                  <select
                    className="form-control mb-3 border border-primary"
                    multiple
                    value={selectedGroup.days || []}
                    onChange={(e) =>
                      setSelectedGroup({
                        ...selectedGroup,
                        days: Array.from(
                          e.target.selectedOptions,
                          (option) => option.value
                        ),
                      })
                    }
                  >
                    <option value="mon">Monday</option>
                    <option value="tue">Tuesday</option>
                    <option value="wed">Wednesday</option>
                    <option value="thu">Thursday</option>
                    <option value="fri">Friday</option>
                    <option value="sat">Saturday</option>
                    <option value="sun">Sunday</option>
                  </select>

                  {/* Start Time */}
                  <label className="form-label">Start Time</label>
                  <input
                    className="form-control mb-3 border border-primary"
                    type="time"
                    value={selectedGroup.start || ""}
                    onChange={(e) =>
                      setSelectedGroup({
                        ...selectedGroup,
                        start: e.target.value,
                      })
                    }
                  />

                  {/* End Time */}
                  <label className="form-label">End Time</label>
                  <input
                    className="form-control mb-3 border border-primary"
                    type="time"
                    value={selectedGroup.end || ""}
                    onChange={(e) =>
                      setSelectedGroup({
                        ...selectedGroup,
                        end: e.target.value,
                      })
                    }
                  />

                  {/* Destination */}
                  <label className="form-label">Destination</label>
                  <input
                    className="form-control mb-3 border border-primary"
                    type="text"
                    placeholder="Enter UK phone number"
                    value={selectedGroup.destination || ""}
                    onChange={(e) =>
                      setSelectedGroup({
                        ...selectedGroup,
                        destination: e.target.value,
                      })
                    }
                  />

                  {/* Timeout */}
                  <label className="form-label">Timeout (20-180 seconds)</label>
                  <input
                    className="form-control mb-3 border border-primary"
                    type="number"
                    min="20"
                    max="180"
                    value={selectedGroup.timeout || 20}
                    onChange={(e) =>
                      setSelectedGroup({
                        ...selectedGroup,
                        timeout: e.target.value,
                      })
                    }
                  />

                  {/* Enabled */}
                  <label className="form-label">Enabled</label>
                  <select
                    className="form-control mb-3 border border-primary"
                    value={selectedGroup.enabled || "on"}
                    onChange={(e) =>
                      setSelectedGroup({
                        ...selectedGroup,
                        enabled: e.target.value,
                      })
                    }
                  >
                    <option value="on">On</option>
                    <option value="off">Off</option>
                  </select>

                  {/* Update Button */}
                  <button
                    className="btn btn-primary mt-3"
                    onClick={handleUpdate}
                  >
                    Update
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default HuntGroup;
