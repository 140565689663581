import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import Sidebar from "../Sidebar";

const AlreadyPurchased = () => {
  const [numbers, setNumbers] = useState([]);
  const [stats, setStats] = useState(null);
  const [selectedNumber, setSelectedNumber] = useState(null);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const userId = localStorage.getItem("user_id");

  useEffect(() => {
    const fetchNumbers = async () => {
      try {
        const response = await fetch(
          `https://back.faithlandline.co.uk/api/numbers/user/${userId}`
        );
        const data = await response.json();
        setNumbers(data);
      } catch (error) {
        console.error("Error fetching numbers:", error);
      }
    };

    fetchNumbers();
  }, [userId]);

  const toggleSidebar = (open) => {
    setSidebarOpen(open);
  };

  // Function to fetch stats for a specific number using a POST request
  const fetchStats = async (number) => {
    try {
      const response = await fetch(`https://back.faithlandline.co.uk/api/stats/cdr/summary`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: userId,
          number: number,
        }),
      });

      const data = await response.json();
      setStats(data); // Store the stats data
      setSelectedNumber(number); // Store the selected number
      new window.bootstrap.Modal(document.getElementById("statsModal")).show(); // Show the modal
    } catch (error) {
      console.error("Error fetching stats:", error);
    }
  };

  return (
    <div>
      <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        <Navbar toggleSidebar={toggleSidebar} />

        <div className="container-fluid py-4">
          <div className="row">
            <div className="col-lg-12 col-md-10 mx-auto">
              <div className="card mt-4 shadow-lg">
                <div className="card-header p-3 bg-gradient-primary text-white">
                  <h5 className="mb-0 text-white">Purchased Numbers</h5>
                  <p className="text-sm pt-2">
                    Below you will find a list of the numbers associated with
                    your account, along with any references you have given to
                    the numbers. To make a change to one of the numbers below,
                    simply select the number you wish to update. If you would
                    like to make outbound calls and display your virtual
                    telephone number, find out more about FaithLandLine VoIP{" "}
                    <a
                      href="https://getbootstrap.com/docs/5.0/components/toasts/"
                      target="_blank"
                      className="text-warning fw-bold"
                      rel="noopener noreferrer"
                    >
                      here
                    </a>
                    .
                  </p>
                </div>

                <div className="card-body p-4">
                  <h6 className="text-uppercase text-muted font-weight-bold">
                    Number Translation Service ({numbers.length} number
                    {numbers.length > 1 ? "s" : ""})
                  </h6>

                  {numbers.map((number) => (
                    <div
                      key={number._id}
                      className="translation-service mt-3 p-3 rounded bg-light"
                    >
                      <div className="d-flex align-items-center">
                        <span className="icon me-3">
                          <i className="fas fa-phone-alt"></i>
                        </span>
                        <div>
                          <h5 className="mb-1">{number.number}</h5>
                          <p className="text-sm text-muted">
                            Diverted to{" "}
                            <strong>
                              {number.destination || "No destination set"}
                            </strong>
                          </p>
                          <p className="text-sm text-muted">
                            Tariff: <strong>{number.tariffPrice}</strong>, Price:{" "}
                            <strong>${number.price}</strong>
                          </p>
                        </div>
                      </div>
                      {/* Button to fetch stats */}
                      <button
                        className="btn btn-primary mt-3"
                        onClick={() => fetchStats(number.number)}
                      >
                        View Call Summary
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </main>

      {/* Modal to show stats */}
      <div
        className="modal fade"
        id="statsModal"
        tabIndex="-1"
        aria-labelledby="statsModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="statsModalLabel">
                Call Summary for {selectedNumber}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {stats ? (
                <div>
                  <p>Month: {stats.month}</p>
                  <p>Total Calls: {stats.calls}</p>
                  <p>Total Duration (Seconds): {stats.durationSeconds}</p>
                  <p>Total Duration (Minutes): {stats.durationMinutes}</p>
                  <p>Calls Answered: {stats.callsAnswered}</p>
                  <p>Calls Engaged: {stats.callsEngaged}</p>
                  <p>Calls Unanswered: {stats.callsUnanswered}</p>
                </div>
              ) : (
                <p>Loading...</p>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlreadyPurchased;
