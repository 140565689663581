import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";

const BlackList = () => {
  const [blacklistEntries, setBlacklistEntries] = useState([]);
  const [newEntry, setNewEntry] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [blockInternational, setBlockInternational] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = (open) => {
    setSidebarOpen(open);
  };
  // Fetch the current blacklist from the backend API
  useEffect(() => {
    const fetchBlacklist = async () => {
      try {
        const response = await axios.get("https://back.faithlandline.co.uk/api/blacklist");
        const { entry } = response.data; // Adjusted to match the API response format

        if (entry && entry.length > 0) {
          setBlacklistEntries(entry);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching blacklist:", error);
        setLoading(false);
      }
    };

    fetchBlacklist();
  }, []);

  // Handle removing an entry from the blacklist (in UI and backend)
  const handleRemoveEntry = async (index) => {
    const entryToRemove = blacklistEntries[index];

    try {
      // API call to remove the blacklist entry
      const response = await axios.post("https://back.faithlandline.co.uk/api/blacklist", {
        number: entryToRemove.number,
        action: "remove",
      });

      // Check if the removal was successful
      if (response.data.apiResponse.Response === "200") {
        alert(response.data.apiResponse.Message);

        // Remove the entry from the state (UI)
        setBlacklistEntries(blacklistEntries.filter((_, i) => i !== index));
      }
    } catch (error) {
      console.error("Error removing blacklist entry:", error);
    }
  };

  // Handle adding a new entry to the blacklist and updating the backend
  const handleAddEntry = async () => {
    if (newEntry && newDescription) {
      const postData = {
        number: newEntry,
        action: "add",
        blockinternational: blockInternational ? "on" : "off",
        description: newDescription,
      };

      try {
        const response = await axios.post(
          "https://back.faithlandline.co.uk/api/blacklist",
          postData
        );

        if (response.data.apiResponse.Response === "200") {
          alert(response.data.apiResponse.Message);
          setBlacklistEntries([
            ...blacklistEntries,
            {
              number: newEntry,
              description: newDescription,
            },
          ]);
          setNewEntry("");
          setNewDescription("");
          setBlockInternational(false);
          window.location.reload();
        }
      } catch (error) {
        console.error("Error adding blacklist entry:", error);
      }
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  

  return (
    <div>
       <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
  
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
      <Navbar toggleSidebar={toggleSidebar} />
        <div className="container-fluid py-4">
          <div className="row">
            <div className="col-lg-12 col-md-10 mx-auto">
              {/* Blacklist Card */}
              <div className="card p-4 shadow-lg">
                <h3 className="mb-3 text-primary">Blacklist</h3>
                <p>
                  You can specify a list of up to 10 callers whom you wish to
                  blacklist free of charge. If you need to blacklist more than
                  10 numbers, please contact customer services.
                </p>
                {blacklistEntries.length === 0 ? (
                  <p className="text-muted">
                    Your blacklist is currently empty.
                  </p>
                ) : (
                  <div>
                    {/* Table Header */}
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <strong>Blocked Number</strong>
                      </div>
                      <div className="col-md-6">
                        <strong>Description</strong>
                      </div>
                    </div>

                    {/* Display blacklisted entries */}
                    {blacklistEntries.map((entry, index) => (
                      <div className="row mb-2" key={index}>
                        <div className="col-md-6">
                          <input
                            type="text"
                            className="form-control"
                            value={entry.number}
                            readOnly
                          />
                        </div>
                        <div className="col-md-6 d-flex align-items-center">
                          <input
                            type="text"
                            className="form-control"
                            value={entry.description}
                            readOnly
                          />
                          <button
                            className="btn btn-outline-danger ms-2"
                            onClick={() => handleRemoveEntry(index)}
                          >
                            &#x2715;
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                {/* Add Blacklist Entry */}
                <div className="row mb-3">
                  <div className="col-md-6">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Add Blacklist Entry"
                      value={newEntry}
                      onChange={(e) => setNewEntry(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6 d-flex">
                    <input
                      type="text"
                      className="form-control mx-3"
                      placeholder="Add Description"
                      value={newDescription}
                      onChange={(e) => setNewDescription(e.target.value)}
                    />
                    <button
                      className="btn btn-outline-primary w-100"
                      onClick={handleAddEntry}
                    >
                      Add
                    </button>
                  </div>
                </div>

                <hr />

                {/* Block International Callers */}
                <div className="form-check mb-3 d-flex">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="blockInternational"
                    checked={blockInternational}
                    onChange={() => setBlockInternational(!blockInternational)}
                  />
                  <label
                    className="form-check-label mt-1 mx-2"
                    htmlFor="blockInternational"
                  >
                    Block international callers
                  </label>
                </div>

                {/* Save Changes Button */}
                <div className="d-flex justify-content-end">
                  <button
                    className="btn btn-primary px-4"
                    onClick={handleAddEntry}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default BlackList;
